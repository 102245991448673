<template>
  <div>
    <Banner
      title="Inloggen"
      description="Login bij het Excez Solution Platform" />

    <section id="register_section" class="register_section sec_ptb_120
    bg_gray half_bg_white clearfix">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between
        justify-content-md-center justify-content-sm-center">

          <div class="col-lg-6 col-md-7 order-last">
            <div class="signin_image" data-aos="fade-up" data-aos-delay="300">
              <img src="../assets/images/register/img_1.png" alt="image_not_found">
            </div>
          </div>

          <div class="col-lg-6 col-md-7 col-sm-8">
            <div class="section_title increase_size mb-50" data-aos="fade-up" data-aos-delay="400">
              <h2 class="title_text mb-30">Inloggen</h2>
              <p class="mb-0">
                Login bij het Excez Solution Platform
              </p>
            </div>

            <div class="alert alert-danger mb-4" :style="{ opacity: errorOpacity }">
              Incorrect e-mailadres en/of wachtwoord.
            </div>
            <div class="signin_form" data-aos="fade-up" data-aos-delay="500">
              <form action="#">

                <div class="form_item">
                  <h4 class="input_title">E-mailadres</h4>
                  <input type="text" name="username" placeholder="E-mailadres">
                </div>

                <div class="form_item">
                  <h4 class="input_title">Wachtwoord</h4>
                  <input type="password" name="password" placeholder="**********">
                </div>

                <div class="row mb-50">
                  <div class="col-6">
                    <div class="check_box">
                      <input id="remember_btn" type="checkbox">
                      <label for="remember_btn">Ingelogd blijven</label>
                    </div>
                  </div>
                </div>

                <button
                  @click="login"
                  :class="{ disabled: busy }"
                  :disabled="busy"
                  type="button"
                  class="btn bg_default_blue mb-30">
                    <span v-if="busy">
                      <i class="fal fa-spin fa-circle-notch"></i>
                      Bezig met inloggen
                    </span>
                    <span v-else>Inloggen</span>
                </button>

              </form>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Banner from '@/views/components/Banner.vue';

export default {
  components: { Banner },
  data() {
    return {
      busy: false,
      errorOpacity: 0,
    };
  },
  methods: {
    login() {
      this.busy = true;
      setTimeout(() => {
        this.errorOpacity = 1;
        this.busy = false;
      }, 1000);
    },
  },
};
</script>
